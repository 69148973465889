export const MetamaskFoxLogo = () => (
  <svg
    width="27"
    height="26"
    viewBox="0 0 27 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M25.2148 0.942841L15.1025 8.58272L16.983 4.08028L25.2148 0.942841Z"
      fill="#E17726"
      stroke="#E17726"
    />
    <path
      d="M1.88965 0.942841L11.9119 8.65402L10.1215 4.08028L1.88965 0.942841Z"
      fill="#E27625"
      stroke="#E27625"
    />
    <path
      d="M21.5739 18.6572L18.8833 22.854L24.6446 24.4737L26.2949 18.7488L21.5739 18.6572Z"
      fill="#E27625"
      stroke="#E27625"
    />
    <path
      d="M0.819336 18.7488L2.4597 24.4737L8.21097 22.854L5.53038 18.6572L0.819336 18.7488Z"
      fill="#E27625"
      stroke="#E27625"
    />
    <path
      d="M7.90089 11.5674L6.30054 14.0325L12.0018 14.2974L11.8118 8.03265L7.90089 11.5674Z"
      fill="#E27625"
      stroke="#E27625"
    />
    <path
      d="M19.2034 11.5674L15.2326 7.96133L15.1025 14.2973L20.8038 14.0325L19.2034 11.5674Z"
      fill="#E27625"
      stroke="#E27625"
    />
    <path
      d="M8.21094 22.854L11.6617 21.1529L8.69104 18.7896L8.21094 22.854Z"
      fill="#E27625"
      stroke="#E27625"
    />
    <path
      d="M15.4426 21.1529L18.8834 22.854L18.4133 18.7896L15.4426 21.1529Z"
      fill="#E27625"
      stroke="#E27625"
    />
    <path
      d="M18.8834 22.8541L15.4426 21.1529L15.7227 23.4347L15.6927 24.4024L18.8834 22.8541Z"
      fill="#D5BFB2"
      stroke="#D5BFB2"
    />
    <path
      d="M8.21094 22.8541L11.4116 24.4024L11.3916 23.4347L11.6617 21.1529L8.21094 22.8541Z"
      fill="#D5BFB2"
      stroke="#D5BFB2"
    />
    <path
      d="M11.4717 17.282L8.61108 16.4263L10.6315 15.479L11.4717 17.282Z"
      fill="#233447"
      stroke="#233447"
    />
    <path
      d="M15.6326 17.282L16.4728 15.479L18.5032 16.4263L15.6326 17.282Z"
      fill="#233447"
      stroke="#233447"
    />
    <path
      d="M8.21111 22.854L8.71122 18.6572L5.53052 18.7488L8.21111 22.854Z"
      fill="#CC6228"
      stroke="#CC6228"
    />
    <path
      d="M18.3933 18.6572L18.8834 22.854L21.574 18.7488L18.3933 18.6572Z"
      fill="#CC6228"
      stroke="#CC6228"
    />
    <path
      d="M20.8038 14.0325L15.1025 14.2974L15.6327 17.282L16.4728 15.479L18.5033 16.4264L20.8038 14.0325Z"
      fill="#CC6228"
      stroke="#CC6228"
    />
    <path
      d="M8.61117 16.4264L10.6316 15.479L11.4718 17.282L12.0019 14.2974L6.30066 14.0325L8.61117 16.4264Z"
      fill="#CC6228"
      stroke="#CC6228"
    />
    <path
      d="M6.30066 14.0325L8.69119 18.7896L8.61117 16.4264L6.30066 14.0325Z"
      fill="#E27525"
      stroke="#E27525"
    />
    <path
      d="M18.5035 16.4264L18.4135 18.7896L20.804 14.0325L18.5035 16.4264Z"
      fill="#E27525"
      stroke="#E27525"
    />
    <path
      d="M12.0019 14.2974L11.4718 17.282L12.1419 20.8066L12.292 16.1615L12.0019 14.2974Z"
      fill="#E27525"
      stroke="#E27525"
    />
    <path
      d="M15.1027 14.2974L14.8226 16.1513L14.9627 20.8066L15.6328 17.282L15.1027 14.2974Z"
      fill="#E27525"
      stroke="#E27525"
    />
    <path
      d="M15.6327 17.282L14.9625 20.8065L15.4426 21.1529L18.4133 18.7896L18.5033 16.4263L15.6327 17.282Z"
      fill="#F5841F"
      stroke="#F5841F"
    />
    <path
      d="M8.61108 16.4263L8.6911 18.7896L11.6618 21.1529L12.1419 20.8065L11.4717 17.282L8.61108 16.4263Z"
      fill="#F5841F"
      stroke="#F5841F"
    />
    <path
      d="M15.6926 24.4024L15.7226 23.4346L15.4625 23.2105H11.6417L11.3916 23.4346L11.4116 24.4024L8.21094 22.854L9.33118 23.7912L11.6017 25.3904H15.4925L17.773 23.7912L18.8833 22.854L15.6926 24.4024Z"
      fill="#C0AC9D"
      stroke="#C0AC9D"
    />
    <path
      d="M15.4426 21.1529L14.9625 20.8065H12.1419L11.6618 21.1529L11.3917 23.4347L11.6418 23.2106H15.4626L15.7227 23.4347L15.4426 21.1529Z"
      fill="#161616"
      stroke="#161616"
    />
    <path
      d="M25.6449 9.08186L26.4951 4.86464L25.2148 0.942841L15.4426 8.32806L19.2035 11.5674L24.5146 13.1463L25.6849 11.7507L25.1748 11.3738L25.985 10.62L25.3648 10.1311L26.175 9.4995L25.6449 9.08186Z"
      fill="#763E1A"
      stroke="#763E1A"
    />
    <path
      d="M0.609375 4.86464L1.46957 9.08186L0.919443 9.4995L1.73962 10.1311L1.11949 10.62L1.92967 11.3738L1.41955 11.7507L2.58981 13.1463L7.90099 11.5674L11.6618 8.32806L1.88966 0.942841L0.609375 4.86464Z"
      fill="#763E1A"
      stroke="#763E1A"
    />
    <path
      d="M24.5147 13.1463L19.2035 11.5674L20.8039 14.0325L18.4133 18.7896L21.574 18.7488H26.2951L24.5147 13.1463Z"
      fill="#F5841F"
      stroke="#F5841F"
    />
    <path
      d="M7.9009 11.5674L2.58973 13.1463L0.819336 18.7488H5.53038L8.69107 18.7896L6.30055 14.0325L7.9009 11.5674Z"
      fill="#F5841F"
      stroke="#F5841F"
    />
    <path
      d="M15.1024 14.2974L15.4425 8.32806L16.9829 4.08029H10.1213L11.6617 8.32806L12.0018 14.2974L12.1318 16.1717L12.1418 20.8065H14.9624L14.9724 16.1717L15.1024 14.2974Z"
      fill="#F5841F"
      stroke="#F5841F"
    />
  </svg>
);
